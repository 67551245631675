import axios from "axios";

const clearStorage = () => {
  localStorage.removeItem(
    `${process.env.REACT_APP_COGNITO_CLIENT_ID}.accessToken`
  );
  localStorage.removeItem(
    `${process.env.REACT_APP_COGNITO_CLIENT_ID}.signedIn`
  );
  return;
};

const request = async (req, method = null, body = null) => {
  // const token = localStorage.getItem("token");

  let token;
  try {
    token = localStorage.getItem(
      `${process.env.REACT_APP_COGNITO_CLIENT_ID}.accessToken`
    );
    if (!token) {
      clearStorage();
      console.error("No available token");
      window.location.href = "/";
      return { success: false };
    }
  } catch (e) {
    let error = `Error setting token.`;
    console.error(error);
    return;
  }

  const headers = {
    Accesstoken: token,
  };
  let result;

  //GET request
  if (!method) {
    try {
      result = await axios.get(`${process.env.REACT_APP_TNFT_SERVER}/${req}`, {
        headers: headers,
      });
      return result.data;
    } catch (e) {
      if (e.code === "ERR_NETWORK") {
        console.error("Network error");
        clearStorage();
        window.location.href = "/";
        return { success: false };
      }
      if (e.response.status === 401) {
        console.error("Not authorized. Signing out");
        clearStorage();
        window.location.href = "/";
        return { success: false };
      }
      console.error(`Error getting ${req}: ${e.message}`);
      return e.response?.data;
    }
  } else if (method === "post") {
    try {
      result = await axios.post(
        `${process.env.REACT_APP_TNFT_SERVER}/${req}`,
        body,
        { headers: headers }
      );
      return result.data;
    } catch (e) {
      if (e.code === "ERR_NETWORK") {
        console.error("Network error");
        clearStorage();
        window.location.href = "/";
        return { success: false };
      }
      if (e.response.status === 401) {
        console.error("Not authorized. Signing out");
        clearStorage();
        window.location.href = "/";
        return { success: false };
      }
      console.error(`Error posting ${req}: ${e.message}`);
      alert(e.message);
      return result;
    }
  }
};
export default request;

import React from "react";
import { Stack, Avatar, Button, Typography } from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import CloseIcon from "@mui/icons-material/Close";
import request from "../../../utilities/requests";

const PairingButtons = ({ pairing, refresh }) => {
  const [noPairing, setNoPairing] = React.useState(true);

  React.useEffect(() => {
    if (pairing) {
      if (!pairing.noPairing) {
        setNoPairing(false);
      } else if (pairing.noPairing) {
        setNoPairing(true);
      }
    }
  }, [pairing]);
  const handleClick = async (btn) => {
    const body = {
      pairingId: pairing._id,
      like: btn,
    };

    const result = await request("pairing/like", "post", body);
    if (!result?.success) {
      alert(result.error);
    }
    refresh(result.pairing);
  };

  if (pairing?.status === "pending") {
    return (
      <Stack direction="column">
        <Typography variant="h4" align="center" style={{ fontWeight: "bold" }}>
          Pending
        </Typography>
        <Typography align="center" style={{ fontWeight: "bold" }}>
          Waiting for your date to set up the pairing on their side
        </Typography>
      </Stack>
    );
  }

  return (
    <div
      style={{
        height: 150,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {noPairing ? (
        <></>
      ) : (
        <Stack direction="row" spacing={7}>
          <Button style={{ borderRadius: "50%" }}>
            <Avatar
              style={{ height: 60, width: 60, backgroundColor: "#68C371" }}
              onClick={() => handleClick(true)}
            >
              <FavoriteIcon style={{ height: 30, width: 30 }} />
            </Avatar>
          </Button>
          <Button style={{ borderRadius: "50%" }}>
            <Avatar
              style={{ height: 60, width: 60, backgroundColor: "#E36262" }}
              onClick={() => handleClick(false)}
            >
              <CloseIcon style={{ height: 30, width: 30 }} />
            </Avatar>
          </Button>
        </Stack>
      )}
    </div>
  );
};

export default PairingButtons;

import * as React from "react";
import ReactGA from 'react-ga4';
import {
  Grid,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const InfoDialog = ({ open, close }) => {
  const navigate = useNavigate();

  React.useEffect(() =>{
    ReactGA.event({
      category: "matchmaking",
      action: "mm_open_info_dialog"
    });
  },[])

  return (
    <Dialog open={open} onClose={() => close()}>
      <DialogTitle>Matchmaking Tiers</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Love in LA offers multiple membership tiers to better improve your
          chances of finding a match for you.
        </DialogContentText>
        <br />
        <DialogContentText>
          It is completely free to fill out your profile and{" "}
          <span style={{ fontWeight: "bold" }}> by default</span> you are
          included in the the{" "}
          <span style={{ fontWeight: "bold" }}>free tier</span> where you will
          be considered for matches by our matchmakers.
        </DialogContentText>
        <br />
        <DialogContentText style={{ fontWeight: "bold" }}>
          Silver Tier - $99 / year
        </DialogContentText>
        <br />
        <DialogContentText>
          You will receive a 1 hour consultation with one of our matchmakers to
          capture additional details about your preferences. You will be
          considered for matches ahead of free tier members and are thus more
          likely to be matched by our matchmakers.
        </DialogContentText>
        <br />
        <DialogContentText style={{ fontWeight: "bold" }}>
          Gold Tier - $500 (One time fee)
        </DialogContentText>
        <br />
        <DialogContentText>
          Everything from the Silver Tier plus 3 dates that our matchmakers will
          completely plan for you.
        </DialogContentText>
        <br />
        <DialogContentText>
          Just click on the <span style={{ fontWeight: "bold" }}>upgrade</span>{" "}
          button to get started.
        </DialogContentText>
        <br />
        <DialogContentText>
          Still have questions? Click{" "}
          <span>
            <Typography variant="button" style={{ color: "rgb(239, 82, 65)", cursor:'pointer' }} onClick={() => navigate('/contactus', {state: {subject: 'Matchmaking'}})}>
              here
            </Typography>
          </span>{" "}
          to contact us.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => close()} autoFocus>
          Got it
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InfoDialog;

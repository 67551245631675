import React from "react";
import ReactGA from 'react-ga4';
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../contexts/AppContext";
import PairingEvent from "../../components/PairingEvent";
import { CircularProgress } from "@mui/material";

import request from "../../utilities/requests";

const ActiveEvent = () => {
  const navigate = useNavigate();
  const { event, setActiveEvent } = React.useContext(AppContext);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/activeevent", title: "ActiveEvent" });
  },[])

  React.useEffect(() => {
    if (!event) {
      getActiveEvent();
    }
  }, [event]);

  const getActiveEvent = async () => {
    console.log("No active event in context. Retrieving from server");
    const result = await request("events/activeevent");
    if (!result.success) {
      console.error(result.error);
      navigate("/events");
    }
    setActiveEvent(result.event);
  };

  const renderEventType = () => {
    //TODO: account for other events here as they are configured
    if (!event) {
      return (
        <div
          style={{
            height: "80vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress style={{ height: 100, width: 100 }} />
        </div>
      );
    } else if (event.eventType === "Pairing") {
      return <PairingEvent />;
    }
  };

  return <>{renderEventType()}</>;
};

export default ActiveEvent;

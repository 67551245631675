import axios from "axios";
import request from "./requests";

const url = "https://cognito-idp.us-west-1.amazonaws.com/";

export const login = async (email, password) => {
  const headers = {
    "X-Amz-Target": "AWSCognitoIdentityProviderService.InitiateAuth",
    "Content-Type": "application/x-amz-json-1.1",
  };

  const body = {
    AuthParameters: {
      USERNAME: email,
      PASSWORD: password,
    },
    AuthFlow: "USER_PASSWORD_AUTH",
    ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
  };

  try {
    const result = await axios.post(url, body, { headers });
    if (result?.status === 200) {
      console.log("Login success");
      localStorage.setItem(
        `${process.env.REACT_APP_COGNITO_CLIENT_ID}.accessToken`,
        result.data.AuthenticationResult.AccessToken
      );
      localStorage.setItem(
        `${process.env.REACT_APP_COGNITO_CLIENT_ID}.signedIn`,
        "true"
      );

      return { success: true };
    }
  } catch (e) {
    let error = `Error logging in: ${e.message}`;
    if (e?.response?.data?.message) {
      alert(e.response.data.message);
    }
    localStorage.removeItem(
      `${process.env.REACT_APP_COGNITO_CLIENT_ID}.accessToken`
    );
    localStorage.removeItem(
      `${process.env.REACT_APP_COGNITO_CLIENT_ID}.signedIn`
    );

    console.error(error);
    console.log(e);
    return { success: false, error };
  }
};

export const signup = async (email, password, phone, orgId) => {
  const headers = {
    "X-Amz-Target": "AWSCognitoIdentityProviderService.SignUp",
    "Content-Type": "application/x-amz-json-1.1",
  };

  const body = {
    Username: email,
    Password: password,
    ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
    UserAttributes: [
      {
        Name: "phone_number",
        Value: phone,
      },
      {
        Name: "custom:orgId",
        Value: orgId
      }
    ],
  };

  try {
    const result = await axios.post(url, body, { headers });
    if (result?.status === 200) {
      console.log("Signup success");
      return { success: true };
    }
  } catch (e) {
    let error = `Error signing up: ${e?.response?.data?.message}`;
    console.error(error);
    // alert(error);
    return { success: false, error };
  }
};

export const confirm = async (email, code) => {
  let error;
  try {
    const headers = {
      "X-Amz-Target": "AWSCognitoIdentityProviderService.ConfirmSignUp",
      "Content-Type": "application/x-amz-json-1.1",
    };

    const body = {
      Username: email,
      ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
      ConfirmationCode: code,
    };

    const result = await axios.post(url, body, { headers });
    if (result?.status === 200) {
      console.log("Confirm success");
      return { success: true };
    }
  } catch (e) {
    if (e?.response?.data?.__type === "CodeMismatchException") {
      return { success: false, error: "Invalid code. Please try again" };
    }
    error = `Error signing confirming user. Error: ${e.message}`;
    console.error(error);
    return { success: false, error };
  }
};

export const updateEmail = async (email) => {
  let error;
  try {
    const token = localStorage.getItem(
      `${process.env.REACT_APP_COGNITO_CLIENT_ID}.accessToken`
    );
    if (!token) {
      localStorage.removeItem(
        `${process.env.REACT_APP_COGNITO_CLIENT_ID}.signedIn`
      );
      return { success: false };
    }

    const headers = {
      "X-Amz-Target": `AWSCognitoIdentityProviderService.UpdateUserAttributes`,
      "Content-Type": "application/x-amz-json-1.1",
    };

    const body = {
      AccessToken: token,
      UserAttributes: [
        {
          Name: "email",
          Value: email,
        },
      ],
    };
    const result = await axios.post(url, body, { headers });
    if (result?.status === 200) {
      console.log("Email request success");
      return { success: true };
    }
  } catch (e) {
    error = `Error updating email. Error: ${e.message}`;
    console.error(error);
    return { success: false, error };
  }
};

export const updatePhone = async (phone) => {
  let error;
  try {
    const token = localStorage.getItem(
      `${process.env.REACT_APP_COGNITO_CLIENT_ID}.accessToken`
    );
    if (!token) {
      localStorage.removeItem(
        `${process.env.REACT_APP_COGNITO_CLIENT_ID}.signedIn`
      );
      return { success: false };
    }

    const headers = {
      "X-Amz-Target": `AWSCognitoIdentityProviderService.UpdateUserAttributes`,
      "Content-Type": "application/x-amz-json-1.1",
    };

    const body = {
      AccessToken: token,
      UserAttributes: [
        {
          Name: "phone_number",
          Value: phone,
        },
      ],
    };
    const result = await axios.post(url, body, { headers });
    if (result?.status === 200) {
      console.log("Phone update request success");
      return { success: true };
    }
  } catch (e) {
    error = `Error updating phone. Error: ${e.message}`;
    console.error(error);
    return { success: false, error };
  }
};

export const validateEmailCode = async (code, email) => {
  let error;
  try {
    const token = localStorage.getItem(
      `${process.env.REACT_APP_COGNITO_CLIENT_ID}.accessToken`
    );
    if (!token) {
      localStorage.removeItem(
        `${process.env.REACT_APP_COGNITO_CLIENT_ID}.signedIn`
      );
      return { success: false };
    }

    const headers = {
      "X-Amz-Target": `AWSCognitoIdentityProviderService.VerifyUserAttribute`,
      "Content-Type": "application/x-amz-json-1.1",
    };

    const body = {
      AccessToken: token,
      AttributeName: "email",
      Code: code,
    };
    const result = await axios.post(url, body, { headers });
    if (result?.status === 200) {
      console.log("Code confirmed");
      return { success: true };
    } else {
      return {success: false}
    }
  } catch (e) {
    error = `Error updating email. Error: ${e.message}`;
    console.error(error);
    return { success: false, error };
  }
};

export const forgot = async (email) => {
  let error;
  try {
    const headers = {
      "X-Amz-Target": "AWSCognitoIdentityProviderService.ForgotPassword",
      "Content-Type": "application/x-amz-json-1.1",
    };

    const body = {
      Username: email,
      ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID
    };

    const result = await axios.post(url, body, { headers });
    if (result?.status === 200) {
      console.log("Forgot success");
      return { success: true };
    }
  } catch (e) {

    error = `Error requesting reset code. Error: ${e.message}`;
    console.error(error);
    return { success: false, error };
  }
};

export const confirmForgot = async (email, password, code) => {
  let error;
  try {
    const headers = {
      "X-Amz-Target": "AWSCognitoIdentityProviderService.ConfirmForgotPassword",
      "Content-Type": "application/x-amz-json-1.1",
    };

    const body = {
      Username: email,
      ConfirmationCode: code,
      Password: password,
      ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID
    };

    const result = await axios.post(url, body, { headers });
    if (result?.status === 200) {
      console.log("Confirm forgot success");
      return { success: true };
    }
  } catch (e) {
    if (e?.response?.data?.__type == 'CodeMismatchException') {
      error = "Invalid code. Please try again."
    } else {
      error = `Error resetting password.${e?.response?.data?.message}`;
    }
    console.error(error);
    return { success: false, error };
  }
};
